import React from "react";
import { Box, Typography, IconButton, TextField, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0F0F0F",
        color: "#fff",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" }, // Stack in column for small screens, row for medium and up
          width: "100%",
          justifyContent: "space-around",
          gap: "20px", // Add spacing between the items
        }}
      >
        {/* Categories - shown first on mobile */}
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{ color: "#fff", fontWeight: "bold", marginBottom: "15px" }}
          >
            Categories
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            Web Development
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            Mobile Apps
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            Digital Marketing
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            UI/UX Design
          </Typography>
        </Box>

        {/* Information - shown after Categories */}
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{ color: "#fff", fontWeight: "bold", marginBottom: "15px" }}
          >
            Information
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            About Us
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            Services
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            Portfolio
          </Typography>
          <Typography sx={{ color: "#fff", marginBottom: "10px" }}>
            Pricing
          </Typography>
        </Box>

        {/* Contact Section */}
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{ color: "#fff", fontWeight: "bold", marginBottom: "15px" }}
          >
            Contacts
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <PhoneIcon sx={{ marginRight: "10px", color: "#098db0" }} />
            <Typography sx={{ color: "#fff" }}>+047 7586 9258</Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <EmailIcon sx={{ marginRight: "10px", color: "#098db0" }} />
            <Typography sx={{ color: "#fff" }}>example@gmail.com</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon sx={{ marginRight: "10px", color: "#098db0" }} />
            <Typography sx={{ color: "#fff" }}>
              123, New Lenox, London
            </Typography>
          </Box>
        </Box>

        {/* Subscribe to Newsletter - shown last on mobile */}
        <Box sx={{ flex: 1, width: "100%" }}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
          >
            Subscribe to Our Newsletter
          </Typography>
          <TextField
            type="text"
            placeholder="Enter your email"
            variant="outlined"
            sx={{
              width: { xs: "100%", sm: "300px" },
              input: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#098db0" },
                "&:hover fieldset": { borderColor: "#fff" },
              },
            }}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#098db0",
                    color: "#fff",
                    padding: "10px 30px",
                    "&:hover": {
                      backgroundColor: "#086a8f",
                    },
                  }}
                >
                  Subscribe
                </Button>
              ),
            }}
          />
          <Typography
            sx={{
              fontSize: "18px",
              color: "#fff",
              marginBottom: "10px",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            Follow Us On!
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <IconButton sx={{ color: "#098db0" }}>
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: "#098db0" }}>
              <InstagramIcon />
            </IconButton>
            <IconButton sx={{ color: "#098db0" }}>
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Bottom Footer - Copyright */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
          borderTop: "1px solid #555",
          paddingTop: "20px",
        }}
      >
        <Typography sx={{ color: "#fff" }}>
          &copy; 2024 Quantum Culture Technologies. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
