import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Navbar from "./navbar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Footer from "./footer";
import TrialModal from "./TrialModal";
import FreeTrial from "../assets/freetrial.gif";

const Layout = () => {
  const [openTrial, setOpenTrial] = useState(false);
  const [enableTrialBtn, setEnableTrialBtn] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenTrial(true);
      setEnableTrialBtn(true);
    }, 10000);
  }, []);

  return (
    <Box>
      <Navbar />
      <Box>
        {" "}
        {/* Ensuring content is below the Navbar */}
        <Outlet />
      </Box>
      <Footer />
      <Box
        sx={{
          position: "fixed",
          bottom: "0",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          right: { xs: "-1%", sm: "0%" },
          zIndex: 20,
        }}
      >
        {enableTrialBtn && (
          <Box
            component={Button}
            onClick={() => setOpenTrial(true)}
            // sx={{
            //   position: "fixed",
            //   bottom: "60px",
            // right: { xs: "-18%", sm: "-5%", lg: "-2.5%" },
            // }}
          >
            <img
              src={FreeTrial}
              alt="Free Trial"
              style={{
                width: "60px",
                // height: "50px",
              }}
            />
          </Box>
        )}
        <Box
          component={Link}
          to="https://api.whatsapp.com/send/?phone=919042422209&text=Hello"
          target="_blank"
        >
          <Button>
            <WhatsAppIcon
              sx={{
                color: "green",
                fontSize: "50px",
              }}
            />
          </Button>
        </Box>
      </Box>
      <TrialModal openTrial={openTrial} setOpenTrial={setOpenTrial} />
    </Box>
  );
};

export default Layout;
