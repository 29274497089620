import {
  Box,
  Modal,
  TextField,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel1 from "../assets/images/carousel1.png";
import Carousel2 from "../assets/images/carousel2.png";
import Carousel3 from "../assets/images/carousel3.png";
import axios from "../api/axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  height: { xs: "60%", sm: "80%" },
  backgroundColor: "rgba(255,255,255,0.15)",
  boxShadow: "0 8px 32px 0 rgba(9,139,218,.27)",
  backdropFilter: "blur(4px)",
  WebkitBackdropFilter: "blur(4px)",
  borderRadius: "20px",
  padding: "20px",
  margin: "auto",
  p: 4,
};
const CarouselImages = [Carousel1, Carousel2, Carousel3];

const NewTextField = ({
  label,
  type,
  value,
  onChange,
  error,
  helperText,
  Placeholder,
}) => {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      variant="standard"
      error={error}
      helperText={helperText}
      placeholder={Placeholder}
      sx={{
        width: "100%",
        borderColor: "white",
        color: "#fff",
        "& .MuiInput-underline:before": {
          borderBottomColor: "white", // Default bottom border color
        },
        "& .MuiInput-underline:hover:before": {
          borderBottomColor: "white", // Bottom border color on hover
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "#098db0", // Bottom border color when focused
        },
        input: {
          color: "white", // Text color
          "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            WebkitAppearance: "none", // Hide spinner on number inputs
            margin: 0,
          },
          "&[type=number]": {
            MozAppearance: "textfield", // Hide spinner on Firefox
          },
        },
      }}
    />
  );
};
const Contact = ({ handleCloseContact, openContact }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission here
    console.log(formData);
    const res = await axios.post("/mailer", formData);
    if (res.status === 200) {
      setFormData({
        name: "",
        email: "",
        message: "",
        phone: "",
      });
      handleCloseContact();
    }
  };

  return (
    <Modal
      open={openContact}
      onClose={handleCloseContact}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-aos="fade-up"
      data-aos-duration="500"
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", width: "100%", height: "100%", gap: 2 }}>
          {/* Contact Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
              color: "#fff",
              flex: 1,
              width: "100%",
              gap: 2,
            }}
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="500"
          >
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                variant="h3"
                sx={{ textShadow: "2px 2px 4px #000000" }}
              >
                Help Us{" "}
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#098db0", textShadow: "2px 2px 4px #000000" }}
              >
                Reach you Faster!
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                gap: 2,
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                flex: 1.5,
              }}
            >
              <NewTextField
                Placeholder="Name"
                type="text"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />

              <NewTextField
                Placeholder="Phone"
                type="number"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />

              <NewTextField
                Placeholder="Email"
                type="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />

              <NewTextField
                Placeholder="Message"
                type="text"
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              />
            </Box>

            <Box sx={{ display: "flex", flex: 1 }}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  width: "100%",
                  height: "50px",
                  marginTop: "20px",
                  backgroundColor: "#098db0",
                  boxShadow: "2px 2px 4px #000000",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              display: { xs: "none", sm: "flex" },
              color: "#fff",
              backgroundColor: "#098db0",
              width: "2px",
              borderRadius: "50px",
            }}
          />
          {/* image temp */}
          <Box sx={{ display: { xs: "none", sm: "flex" }, flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                borderRadius: "20px",
                flexDirection: "column",
                overflow: "hidden", // Ensure the images don’t overflow
              }}
            >
              <Carousel
                showArrows={true}
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                emulateTouch={true} // Enables touch scrolling on mobile
              >
                {CarouselImages.map((image, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={image}
                    alt="carousel image"
                    sx={{
                      width: "100%", // Ensures the image fills the width of the container
                      height: "100%", // Makes the image fill the height
                      objectFit: "fill", // Crops the image to fit within the box
                    }}
                  />
                ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default Contact;
