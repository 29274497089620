import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Menu, MenuItem, MenuButton, Dropdown } from "@mui/joy";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../assets/logo/logo.png"; // Replace with your logo
import Contact from "./contact"; // Replace with your contact component

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openContact, setOpenContact] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer state

  const handleCloseContact = () => {
    setOpenContact(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  let hasLogoShown = false;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && !hasLogoShown) {
        setShowLogo(true);
        hasLogoShown = true; // Set flag to true
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showLogo && (
        <Box
          data-aos="fade-down"
          data-aos-duration="500"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: { xs: "10px 20px", sm: "0px 64px" },
            position: "fixed",
            backgroundColor: "black",
            width: "100%",
            top: 0,
            height: "100px",
            zIndex: 2,
            boxSizing: "border-box",
            boxShadow: "0px 2px 6px rgba(100, 100, 100, 0.2)",
          }}
        >
          {/* Logo Section */}
          <Box
            component={Link}
            to={"/"}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: { xs: "100%" },
              justifyContent: { xs: "space-between", sm: "flex-start" },
              textDecoration: "none",
            }}
          >
            <img
              src={Logo}
              alt="logo"
              width={70}
              style={{ dropShadow: "1px 2px 2px #000" }}
            />
            <Typography
              sx={{
                fontWeight: "bold",
                marginLeft: { xs: 0, sm: 1 },
                color: "#fff",
                fontSize: { xs: "25px", sm: "32px" },
                whiteSpace: "nowrap",
              }}
            >
              ThatFit Company
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                display: { xs: "flex", sm: "none" },
              }}
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon
                sx={{
                  color: "#098db0",
                  fontSize: "40px",
                }}
              />
            </IconButton>
          </Box>

          {/* Navigation Links for larger screens */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
              },
              justifyContent: "space-around",
              alignItems: "center",
              width: { xs: "0%", sm: "40%" },
            }}
          >
            <Link
              style={{
                color: "#fff",
                fontSize: "18px",
                textDecoration: "none",
              }}
              to="/"
            >
              <Typography>Home</Typography>
            </Link>
            <Link
              style={{
                color: "#fff",
                fontSize: "18px",
                textDecoration: "none",
              }}
              to="/about"
            >
              <Typography>About</Typography>
            </Link>
            <Dropdown>
              <MenuButton
                variant="plain"
                sx={{
                  color: "#fff",
                  padding: "0px",
                  fontSize: "18px",
                  textDecoration: "none",
                  display: "flex",
                  "&:hover": {
                    backgroundColor: "#000",
                    color: "#fff",
                  },
                }}
              >
                <Typography>Training Options</Typography>
                <ExpandMoreIcon />
              </MenuButton>
              <Menu variant="plain">
                <MenuItem
                  component={Link}
                  onClick={() => {
                    handleClose();
                    setIsDrawerOpen(false); // Ensure drawer closes if applicable
                  }}
                  to="/trainings/strength"
                >
                  Strength Training
                </MenuItem>
                <MenuItem
                  component={Link}
                  onClick={() => {
                    handleClose();
                    setIsDrawerOpen(false); // Ensure drawer closes if applicable
                  }}
                  to="/trainings/functional"
                >
                  Functional Training
                </MenuItem>
                <MenuItem
                  component={Link}
                  onClick={() => {
                    handleClose();
                    setIsDrawerOpen(false); // Ensure drawer closes if applicable
                  }}
                  to="/trainings/handbalancing"
                >
                  Hand Balancing
                </MenuItem>
              </Menu>
            </Dropdown>
            <Link
              style={{
                color: "#fff",
                fontSize: "18px",
                textDecoration: "none",
              }}
              onClick={() => setOpenContact(true)}
            >
              <Typography>Contact</Typography>
            </Link>
          </Box>

          {/* Drawer for mobile screens */}

          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{
                width: 250,
                height: "100%",
                backgroundColor: "#098db0",
              }}
            >
              <IconButton
                sx={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                onClick={() => toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
              <List>
                <ListItem
                  button
                  component={Link}
                  to="/"
                  onClick={() => toggleDrawer(false)}
                >
                  <ListItemText primary="Home" sx={{ color: "#fff" }} />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/about"
                  onClick={() => toggleDrawer(false)}
                >
                  <ListItemText primary="About" sx={{ color: "#fff" }} />
                </ListItem>
                <ListItem button onClick={handleClick}>
                  <ListItemText
                    primary="Training Options"
                    sx={{ color: "#fff" }}
                  />
                  {open ? (
                    <ExpandLessIcon sx={{ color: "#fff" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "#fff" }} />
                  )}
                </ListItem>
                <Menu sx={{ backgroundColor: "#333" }}>
                  <MenuItem
                    component={Link}
                    onClick={handleClose}
                    to="/trainings/strength"
                  >
                    Strength Training
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    onClick={handleClose}
                    to="/trainings/functional"
                  >
                    Functional Training
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    onClick={handleClose}
                    to="/trainings/handbalancing"
                  >
                    Hand Balancing
                  </MenuItem>
                </Menu>
                <ListItem button onClick={() => setOpenContact(true)}>
                  <ListItemText primary="Contact" sx={{ color: "#fff" }} />
                </ListItem>
              </List>
            </Box>
          </Drawer>

          <Contact
            openContact={openContact}
            handleCloseContact={handleCloseContact}
          />
        </Box>
      )}
    </>
  );
};

export default Navbar;
