import {
  Box,
  Divider,
  Typography,
  Grid,
  useMediaQuery,
  styled,
  Button,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import "../styles.css";
import ViswaImg from "../../assets/trainers/viswa.png";
import ViswaWeightLift from "../../assets/trainers/viswaweight.png";
import BecomeaMember from "../../assets/images/becomeamember.png";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import Marquee from "react-fast-marquee";
import HandBalance1 from "../../assets/trainers/handbalance1.png";
import HandBalance from "../../assets/trainers/handbalance.png";
import Show from "../../assets/trainers/show.png";
import WeightLift from "../../assets/trainers/weightlift.png";

const contentData = [
  {
    icon: <FitnessCenterIcon sx={{ fontSize: "50px", color: "#098db0" }} />,
    title: "BODY STRENGTH",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  {
    icon: <DirectionsRunIcon sx={{ fontSize: "50px", color: "#098db0" }} />,
    title: "CARDIO EXERCISES",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  {
    icon: <SportsMartialArtsIcon sx={{ fontSize: "50px", color: "#098db0" }} />,
    title: "FREE HAND EXERCISES",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
];

const heroSecCont = [
  {
    number: "01",
    subhead: "motivate",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec.",
  },
  {
    number: "02",
    subhead: "health",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec.",
  },
  {
    number: "03",
    subhead: "fitness",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec.",
  },
];

const galleryItems = [
  {
    type: "image",
    src: HandBalance,
    alt: "Trainer 1",
  },
  { type: "video", src: "video1.mp4", alt: "Trainer Video 1" },
  { type: "image", src: HandBalance1, alt: "Trainer 2" },
  { type: "image", src: Show, alt: "Trainer 3" },
  { type: "video", src: "video2.mp4", alt: "Trainer Video 2" },
  { type: "image", src: WeightLift, alt: "Trainer 4" },
];

const Viswa = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };

  // Styled component for the background
  const GradientBackground = styled(Box)({
    backgroundColor: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
    height: isMobile ? "400px" : isTablet ? "500px" : "600px",
  });
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#000",
        paddingTop: "100px",
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          position: "relative",
          //   alignItems: "center",
          display: "flex",
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        <Typography
          className="anton-regular"
          sx={{
            color: "#fff",
            marginTop: "100px",
            fontWeight: "bolder",
            fontSize: { xs: "35vw", sm: "16vw" },
            whiteSpace: { xs: "none", sm: "nowrap" },
          }}
        >
          Viswa Srikar
        </Typography>
        <Box
          component="img"
          src={ViswaImg}
          alt="viswa"
          sx={{
            position: "absolute",
            width: { xs: "100%", sm: "50%" },
            top: { xs: "25%", sm: "-2%" },
            left: "50%",
            transform: "translateX(-50%)",
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            position: "absolute",
            right: "0",
            bottom: "-20%",
            flexWrap: "wrap",
            gap: "2px",
          }}
        >
          {heroSecCont.map((content) => {
            return (
              <Box
                key={content.number}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "max-content",
                  justifyContent: "space-between",
                  backgroundColor: "#098db0",
                  padding: "50px 25px",
                  gap: "20px",
                }}
              >
                <Typography
                  className="anton-regular"
                  sx={{
                    fontSize: "60px",
                    fontWeight: "bolder",
                    color: "transparent",
                    WebkitTextStroke: "2px #000",
                    letterSpacing: "5px",
                  }}
                >
                  {content.number}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "40px",
                    fontWeight: "bolder",
                    textTransform: "uppercase",
                  }}
                >
                  {content.subhead}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    textAlign: "justify",
                    width: "200px",
                    fontSize: "16px",
                  }}
                >
                  {content.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* About Me Sec */}
      <Box
        sx={{
          backgroundColor: "#101010",
          padding: "15% 20px 0% 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "max-content",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Left Section */}
          <Grid item xs={12} md={5} sx={{ position: "relative" }}>
            {/* <Box
              sx={{
                position: "absolute",
                width: "50px",
                height: "80%",
                backgroundColor: "#098db0",
              }}
            ></Box> */}
            <Box
              component="img"
              src={ViswaWeightLift} // Replace with actual image path
              alt="Fitness Models"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50px",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: "0px",
                left: "0px",
                backgroundColor: "#000",
                textAlign: "center",
                padding: "50px",
                borderTopRightRadius: "50px",
              }}
            >
              <Box
                sx={{
                  color: "#098db0",
                  fontSize: "70px",
                  fontWeight: "bold",
                }}
              >
                10+
              </Box>
              <Box
                sx={{
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: "bold",
                  backgroundColor: "rgba(0,0,0,0.7)",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              >
                YEARS OF
                <br />
                EXPERIENCE
              </Box>
            </Box>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={7}>
            <Typography
              variant="h6"
              sx={{ color: "#098db0", fontWeight: "bold", mb: 2 }}
            >
              WHO AM I
            </Typography>
            <Typography
              variant="h3"
              sx={{ color: "#fff", fontWeight: "bolder", mb: 2 }}
            >
              THE WORLD OF RESULTS
            </Typography>
            <Typography
              sx={{
                color: "#aaa",
                mb: 4,
                textAlign: "justify",
                fontSize: "18px",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Typography>

            {/* Content Sections */}
            {contentData.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  mb: 3,
                }}
              >
                {item.icon}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      color: "#fff",
                      fontWeight: "bolder",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#aaa",
                      fontSize: "16px",
                      textAlign: "justify",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>

      {/* Trainer's Best */}
      <Box style={{ padding: "20px" }}>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            color: "#fff",
            textAlign: "center",
            fontWeight: "bolder",
            padding: "20px 0px",
          }}
        >
          Trainer's Best
        </Typography>
        <Grid container spacing={2}>
          {galleryItems.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              onClick={() => handleOpen(item)}
            >
              {item.type === "image" ? (
                <img
                  src={item?.src}
                  alt={item?.alt}
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <video
                  src={item?.src}
                  alt={item?.alt}
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                  controls
                />
              )}
            </Grid>
          ))}
        </Grid>

        {/* Modal for larger view */}
        <Modal open={open} onClose={handleClose}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="rgba(0, 0, 0, 0.8)"
            padding={2}
          >
            <Box maxWidth="60%" maxHeight="60%">
              {selectedItem?.type === "image" ? (
                <img
                  src={selectedItem?.src}
                  alt={selectedItem?.alt}
                  style={{ width: "80%", height: "auto", borderRadius: "8px" }}
                />
              ) : (
                <video
                  src={selectedItem?.src || ""}
                  alt={selectedItem?.alt}
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                  controls
                />
              )}
            </Box>
          </Box>
        </Modal>
      </Box>

      {/* Become a Member */}
      <Box
        sx={{
          padding: "100px 0px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <GradientBackground
          sx={{
            height: { xs: "max-content", sm: "200px" },
            backgroundColor: "#098db0",
            width: "80%",
            // backgroundImage: `Url(${BackgroundOverlay}) `,
            // opacity: 0.5,
            // backgroundSize: "cover", // Adjusts the size of the pattern
            // backgroundPosition: "center", // Centers the pattern
            // backgroundRepeat: "no-repeat", // Prevents the pattern from repeating
            borderRadius: "8px", // Optional: Adds rounded corners
          }}
        >
          <Grid
            container
            sx={{
              maxWidth: "1200px",
              position: "relative",
              alignItems: "center",
            }}
          >
            {/* Left Image Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={BecomeaMember}
                alt="Fitness Model"
                sx={{
                  width: { xs: "100%", sm: "80%" },
                  height: "auto",
                  maxHeight: "600px",
                  objectFit: "cover",
                  borderRadius: "5px",
                  marginLeft: isMobile ? "-50px" : "-150px",
                }}
              />
            </Grid>

            {/* Right Content Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: isTablet ? "10px" : "20px",
                gap: 2,
              }}
            >
              <Typography
                variant={isMobile ? "h6" : "h4"}
                sx={{ color: "#fff", fontWeight: "bold" }}
              >
                ALL PROGRESS TAKES PLACE OUTSIDE THE COMFORT ZONE
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: "medium",
                  fontSize: isMobile ? "14px" : "18px",
                }}
              >
                GET 20% CUT OFF FOR THE FIRST REGISTRATION
              </Typography>

              {/* Button */}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  padding: "12px 24px",
                  "&:hover": {
                    backgroundColor: "transparent",
                    border: "2px solid",
                  },
                }}
              >
                BECOME A MEMBER
              </Button>
            </Grid>
          </Grid>
        </GradientBackground>
      </Box>
    </Box>
  );
};

export default Viswa;
