import React, { useEffect, Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Components/layout";
import AOS from "aos";
import "aos/dist/aos.css";
import Viswa from "./pages/trainers/viswa";
import "./pages/styles.css";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/about"));
const StrengthTrainings = lazy(() =>
  import("./pages/trainings/StrengthTrainings")
);
const FunctionalTraining = lazy(() => import("./pages/trainings/functional"));
const HandBalancing = lazy(() => import("./pages/trainings/handbalancing"));

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              backgroundColor: "#000",
            }}
          >
            <div className="loader"></div>
          </div>
        }
      >
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/trainings">
              <Route path="strength" element={<StrengthTrainings />} />
              <Route path="functional" element={<FunctionalTraining />} />
              <Route path="handbalancing" element={<HandBalancing />} />
            </Route>
            <Route path="/trainers/viswa" element={<Viswa />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
