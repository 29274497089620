import React from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import "./styles.css";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#2f2f2f",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TrialModal = ({ setOpenTrial, openTrial }) => {
  const handleOpenTrial = () => setOpenTrial(true);
  const handleCloseTrial = () => setOpenTrial(false);

  return (
    <Modal
      open={openTrial}
      onClose={handleCloseTrial}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "24px",
            }}
          >
            Help Us Reach You Faster
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Button
              sx={{
                display: "flex",
                gap: "5px",
                backgroundColor: "#fff",
                color: "#098db0",
                width: "120px",
              }}
            >
              <EmailIcon />
              Email
            </Button>
            <Button
              sx={{
                display: "flex",
                gap: "5px",
                backgroundColor: "#fff",
                color: "#098db0",
                width: "120px",
              }}
            >
              <WifiCalling3Icon /> WhatsApp
            </Button>
          </Box>
          <Divider />
          <Button
            // className="btn-shine"
            sx={{
              color: "#fff",
              padding: "10px 20px",
              backgroundColor: "#098db0",
              borderRadius: "10px",
            }}
          >
            {" "}
            Download Fitness Sample
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TrialModal;
